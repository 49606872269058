export const policyTypeList = [
    { lable: "Medical/Health Insurance Policy", value: "Medical/Health Insurance Policy" },
    { lable: "Motor Insurance policy", value: "Motor Insurance policy" },
    { lable: "Marine Insurance policy", value: "Marine Insurance policy" },
    { lable: "Aviation Insrance policy", value: "Aviation Insrance policy" },
    { lable: "Personal Accident Cover policy", value: "Personal Accident Cover policy" },
    { lable: "Group Personal Accident cover policy", value: "Group Personal Accident cover policy" },
    { lable: "Travel Insurance policy", value: "Travel Insurance policy" },
    { lable: "Crop Insurance policy", value: "Crop Insurance policy" },
    { lable: "Livestock Insurance policy", value: "Livestock Insurance policy" },
    { lable: "Blood stock Insurance policy", value: "Blood stock Insurance policy" },
    { lable: "Forestry Insurance policy", value: "Forestry Insurance policy" },
    { lable: "WIBA Insurance policy", value: "WIBA Insurance policy" },
    { lable: "Education Insurance policy", value: "Education Insurance policy" },
    { lable: "Fire Insurance policy", value: "Fire Insurance policy" },
    { lable: "Cyber Insurance policy", value: "Cyber Insurance policy" },
    { lable: "Business Insurance policy", value: "Business Insurance policy" },
    { lable: "Life Insurance policy", value: "Life Insurance policy" },
    { lable: "Engineering Insurance policy", value: "Engineering Insurance policy" },
    { lable: "Theft Insurance policy", value: "Theft Insurance policy" },
    { lable: "Micro Insurance policy", value: "Micro Insurance policy" },
    { lable: "Miscellaneous Insurance policy", value: "Miscellaneous Insurance policy" },
    { lable: "Public Liability Insurance policy", value: "Public Liability Insurance policy" },
    { lable: "Pension plan benefits policy", value: "Pension plan benefits policy" },
    { lable: "Annuity Insurance policy", value: "Annuity Insurance policy" },
    { lable: "Funeral Insurance policy", value: "Funeral Insurance policy" },
    { lable: "Goods in Transit Insurance policy", value: "Goods in Transit Insurance policy" },
    { lable: "Money Insurance policy", value: "Money Insurance policy" },
    { lable: "Bonds insurance", value: "Bonds insurance" },
    { lable: "Fidelity Guarantee Insurance", value: "Fidelity Guarantee Insurance" },
    { lable: "Contractors All Risk Insurance", value: "Contractors All Risk Insurance" },
]