import { LoadingButton } from "@mui/lab";
import { Container, CircularProgress, Stack } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Button from "@mui/material/Button";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import { useState } from "react";
import * as yup from "yup";
import { apipost } from "../../../service/api";
import { useNavigate } from 'react-router-dom';
import { StyledRoot, StyledSection, StyledContent } from "./styleConf";
import useResponsive from '../../../hooks/useResponsive';

export default function VerifyOTP() {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const mdUp = useResponsive('up', 'md');

    // -----------  validationSchema
    const validationSchema = yup.object({
        otp: yup.string().required("OTP is required"),
    });

    // -----------   initialValues
    const initialValues = {
        otp: "",
    };

    // edit api
    const verifyOTP = async (values) => {
        setIsLoading(true)

        // try {
        const result = await apipost('api/user/verifyotp', values)
        if (result && result.status === 200) {
            const otp_id = result?.data?.resetcode?._id;
            sessionStorage.setItem('otp_id', otp_id);
            navigate(`/resetpassword/${otp_id}`)
        } else {
            navigate('/verifyotp')
        }
        // } catch (error) {
        //     console.log(error);
        // }
        setIsLoading(false)
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            const userData = {
                otp: values.otp,
            }
            verifyOTP(userData)
        },
    });

    const back = () => {
        navigate('/sendotp');
    }
    return (
        <>
        <StyledRoot>
        {mdUp && (
            <StyledSection>
                <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>OTP Verification</Typography>
                <img src="/assets/illustrations/illustration_login.png" alt="Forgot Password" />
            </StyledSection>
        )}

        <Container maxWidth="sm">
            <StyledContent>
            <img src="/assets/AIA.png" width={200} style={{ marginLeft: "-7px" }} />
            <Typography variant="h4" gutterBottom mb={4}>A One Time Password has been sent to your email kindly retrieve it.</Typography>
            <form>
                <Stack spacing={3} mb={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <FormLabel>Enter the OTP <span style={{ color: "red" }}>*</span></FormLabel>
                        <TextField
                        id="otp"
                        name="otp"
                        label=""
                        size="small"
                        value={formik.values.otp}
                        onChange={formik.handleChange}
                        fullWidth
                        error={
                            formik.touched.otp &&
                            Boolean(formik.errors.otp)
                        }
                        helperText={
                            formik.touched.otp && formik.errors.otp
                        }
                        />
                    </Grid>
                </Stack>
                <Grid container spacing={3}>
                    <Grid item xs={6} sm={6} md={6}>
                        <LoadingButton onClick={formik.handleSubmit} variant='contained' color='primary' disabled={!!isLoading}>
                        {isLoading ? <CircularProgress size={27} /> : 'Verify OTP'}
                        </LoadingButton>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <Button variant="contained" color="secondary" onClick={back} startIcon={<ArrowBackIosIcon />}>Resend OTP</Button>
                    </Grid>
                </Grid>
            </form>
            </StyledContent>
            </Container>  
            </StyledRoot>  
        </>
    )
}