import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { LoadingButton } from '@mui/lab';
import { Box, Button, CircularProgress, Container, FormLabel, Grid, Stack, TextField, Checkbox, FormControl, ListItemText, MenuItem, Select } from '@mui/material';
import React, { useRef, useState } from 'react';
import { EmailEditor } from 'react-email-editor';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";
import Header from '../../components/Header';
import { apipost } from '../../service/api';

const Add = () => {
    const emailEditorRef = useRef(null);
    const [preview, setPreview] = useState(false);
    const [name, setName] = useState('');
    const [assignTo, setassignTo] = useState([]);
    const userDetails = useSelector((state) => state?.userDetails?.data)
    const [isLoading, setIsLoading] = React.useState(false);
    const userRole = sessionStorage.getItem("userRole");

    const navigate = useNavigate()
    const userid = sessionStorage.getItem('user_id');

    const togglePreview = () => {
        if (preview) {
            emailEditorRef.current?.editor?.hidePreview();
            setPreview(false);
        } else {
            emailEditorRef.current?.editor?.showPreview('desktop');
            setPreview(true);
        }
    };

    const saveDesign = () => {
        setIsLoading(true)
        try {
            if (name !== "" && emailEditorRef.current && emailEditorRef.current.editor.exportHtml) {
                emailEditorRef.current.editor?.exportHtml(async (allData) => {

                    const { html, design } = allData

                    const data = {
                        html,
                        design,
                        name,
                        assignTo,
                        createdBy: userid
                    }

                    const result = await apipost('api/emailtemplate/add', data)
                    if (result && result.status === 200 || result.status === 201) {
                        setName('')
                        navigate('/dashboard/emailtemplate')
                    }
                });
            } else {
                toast.error("Template Name is required")
            }
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false)

    };

    const back = () => {
        navigate('/dashboard/emailtemplate')
    }

    return (
        <div>
            <Container maxWidth>
                <Grid container display="flex" alignItems="center">
                    <Grid container display="flex" alignItems="center">
                        <Stack direction="row" alignItems="center" mb={3} justifyContent={"space-between"} width={"100%"}>
                            <Header
                                title="Create Template"
                            />
                            <Stack direction="row" alignItems="center" justifyContent={"flex-end"} spacing={2}>
                                <Button variant="contained" color="secondary" onClick={togglePreview}>{preview ? "Hide Preview" : "Show Preview"}</Button>
                                <LoadingButton onClick={saveDesign} variant='contained' color='secondary' disabled={!!isLoading}>
                                    {isLoading ? <CircularProgress size={27} /> : 'Save'}
                                </LoadingButton>
                                <Button variant="contained" color="secondary" startIcon={<ArrowBackIosIcon />} onClick={back}>Back</Button>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
                <FormLabel>Template Name</FormLabel>
                <TextField
                    name='policyStartDate'
                    type=''
                    size='small'
                    fullWidth
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <Box height={"680px"} bgcolor={"#edeff1"} className="editerHeight" mt={1}>
                    <EmailEditor ref={emailEditorRef} />
                </Box>
                {userRole !== "user" && (<Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                                <FormLabel>Assign To</FormLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="assignTo"
                                    name="assignTo"
                                    size="small"
                                    multiple
                                    value={assignTo}
                                    onChange={(e) => setassignTo(e.target.value)}
                                    renderValue={(selected) => (
                                        <div>
                                            {
                                                selected.map((value) => {
                                                    const userData = userDetails?.find((user) => user._id === value)
                                                    return (
                                                        <div key={value}>
                                                            {`${userData?.firstName} ${userData?.lastName}`}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                    }
                                >
                                    {
                                        userDetails?.map((user) => (
                                            <MenuItem value={user?._id}>
                                                <Checkbox checked={assignTo.indexOf(user?._id) > -1} />
                                                <ListItemText primary={`${user?.firstName} ${user?.lastName}`} />
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                            </Grid>)}
            </Container>
        </div>
    )
}

export default Add
