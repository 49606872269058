/* eslint-disable react-hooks/exhaustive-deps */
import { Container, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDashboardData } from '../redux/slice/dashboardSlice';
import { fetchUserData } from '../redux/slice/userSlice';
import {
  AppCurrentVisits,
  AppWebsiteVisits,
  AppWidgetSummary,
} from '../sections/@dashboard/app';
// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();

  const allData = useSelector((state) => state?.dashboardDetails?.data)
  const userData = useSelector((state) => state?.userDetails?.data)
  const dispatch = useDispatch();

  useEffect(() => {
    if (userData?.length === 0) {
      dispatch(fetchUserData())
    }
    dispatch(fetchDashboardData())
  }, [])

  return (
    <>
      <Helmet>
        {/* <title> Dashboard | Minimal UI </title> */}
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Dashboard
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={2}>
            <AppWidgetSummary title="Leads" total={allData?.totalLead} icon={'ic:baseline-leaderboard'} />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <AppWidgetSummary title="Contacts" total={allData?.totalContact} color="info" icon={'fluent:book-contacts-24-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <AppWidgetSummary title="Policies" total={allData?.totalPolicy} color="warning" icon={'ic:baseline-policy'} />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <AppWidgetSummary title="Tasks" total={allData?.totalTask} color="primary" icon={'mdi:events'} />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <AppWidgetSummary title="Expired Tasks" total={allData?.totalExpired} color="error" icon={'mdi:events'} />
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Monthly Commissions"
              subheader="Chart"
              chartLabels={allData?.commissionkeys || [] }
              chartData={[
                // {
                //   name: 'Team A',
                //   type: 'line',
                //   fill: 'solid',
                //   data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                // },
                // {
                //   name: 'Team B',
                //   type: 'area',
                //   fill: 'gradient',
                //   data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                // },
                {
                  name: 'Commission',
                  type: 'column',
                  fill: 'solid',
                  data: allData?.commissionvalues || [],
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Acquired Policies"
              chartData={allData?.policyMode || []}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
