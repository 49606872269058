import { useState } from 'react';
import ClearIcon from "@mui/icons-material/Clear";
import { Checkbox, Autocomplete, CircularProgress, FormControl, FormHelperText, FormLabel, Grid, Select, TextField, ListItemText, MenuItem } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchUserData } from "../../redux/slice/userSlice";

import { LoadingButton } from '@mui/lab';
import { policyTypeList } from '../../_mock/data';
import { apiput, apiget } from '../../service/api';
import Palette from '../../theme/palette';

const Edit = (props) => {
    const { open, handleClose, setUserAction, policyData, fetchPolicy } = props
    const [isLoading, setIsLoading] = useState(false);
    const userDetails = useSelector((state) => state?.userDetails?.data)
    const dispatch = useDispatch();
    const userid = sessionStorage.getItem('user_id')
    // -----------  validationSchema
    const validationSchema = yup.object({
        policyType: yup.string().required("Policy Type is required"),
        policyStartDate: yup.date().required("Policy Start Date is required"),
        policyEndDate: yup.date().required("Policy End Date is required"),
        policyStatus: yup.string().required("Policy Status is required"),
        coverageAmounts: yup.number().required("Coverage Amounts is required"),
        deductibles: yup.number().required("Deductions is required"),
        limits: yup.number().required("Limits is required"),
        insuredPersonName: yup.string().required("Person Name is required"),
        insuredPersonDateOfBirth: yup.date().required("Date of Birth is required"),
        relationshipToTheInsured: yup.string().required("Relationship To The Insured is required"),
        phoneNumber: yup.string().matches(/^254[0-9]{9}$/, 'Phone Number is invalid').required('Phone Number is required'),
        emailAddress: yup.string().email('Invalid email').required("Email is required"),
        additionalPhoneNumber: yup.string().matches(/^254[0-9]{9}$/, 'Phone Number is invalid'),
        additionalEmailAddress: yup.string().email('Invalid email'),
        underwriterContactPhone: yup.string().matches(/^254[0-9]{9}$/, 'Phone Number is invalid'),
        underwriterContactEmail: yup.string().email('Invalid email'),
        underwriterContactPhone2: yup.string().matches(/^254[0-9]{9}$/, 'Phone Number is invalid'),
        underwriterContactEmail2: yup.string().email('Invalid email'),
    });

    // -----------   initialValues
    const initialValues = {
        policyType: policyData?.policyType,
        policyStartDate: policyData?.policyStartDate,
        policyEndDate: policyData?.policyEndDate,
        policyStatus: policyData?.policyStatus,
        coverageAmounts: policyData?.coverageAmounts,
        deductibles: policyData?.deductibles,
        limits: policyData?.limits,
        insuredPersonName: policyData?.contact_id ? `${policyData?.contact_id?.firstName} ${policyData?.contact_id?.lastName}` : policyData?.insuredPersonName,
        insuredPersonDateOfBirth: policyData?.insuredPersonDateOfBirth,
        phoneNumber: policyData?.phoneNumber,
        emailAddress: policyData?.emailAddress,
        metaProfile: policyData?.metaProfile,
        relationshipToTheInsured: policyData?.relationshipToTheInsured,
        additionalInsuredPersonName: policyData?.additionalInsuredPersonName,
        additionalInsuredDateOfBirth: policyData?.additionalInsuredDateOfBirth,
        additionalRelationshipToTheInsured: policyData?.additionalRelationshipToTheInsured,
        additionalPhoneNumber: policyData?.additionalPhoneNumber,
        additionalEmailAddress: policyData?.additionalEmailAddress,
        additionalMetaProfile: policyData?.additionalMetaProfile,
        premiumAmount: policyData?.premiumAmount,
        FrequencyOfPremiumPayments: policyData?.FrequencyOfPremiumPayments,
        underwriterName: policyData?.underwriterName,
        underwriterContactName: policyData?.underwriterContactName,
        underwriterContactPhone: policyData?.underwriterContactPhone,
        underwriterContactEmail: policyData?.underwriterContactEmail,
        underwriterContactName2: policyData?.underwriterContactName2,
        underwriterContactPhone2: policyData?.underwriterContactPhone2,
        underwriterContactEmail2: policyData?.underwriterContactEmail2,
        underwriterDecisions: policyData?.underwriterDecisions,
        commission: policyData?.commission,
        assigned_agent: (policyData?.assigned_agent || []).map((agent) => agent._id) || [],
    };

    // edit Policy api
    const editPolicy = async (values) => {
        setIsLoading(true)
        try {
            const data = values;
            const result = await apiput(`api/policy/edit/${policyData?._id}`, data)
            setUserAction(result)
            if (result && result.status === 200) {
                handleClose();
                fetchPolicy();
            }

        } catch (error) {
            console.log(error);
        }
        setIsLoading(false)

    }


    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            const policyData = {
                policyType: values.policyType,
                policyStartDate: values.policyStartDate,
                policyEndDate: values.policyEndDate,
                policyStatus: values.policyStatus,
                coverageAmounts: values.coverageAmounts,
                deductibles: values.deductibles,
                limits: values.limits,
                insuredPersonName: values.insuredPersonName,
                insuredPersonDateOfBirth: values.insuredPersonDateOfBirth,
                phoneNumber: values.phoneNumber,
                emailAddress: values.emailAddress,
                metaProfile: values.metaProfile,
                relationshipToTheInsured: values.relationshipToTheInsured,
                additionalInsuredPersonName: values.additionalInsuredPersonName,
                additionalInsuredDateOfBirth: values.additionalInsuredDateOfBirth,
                additionalRelationshipToTheInsured: values.additionalRelationshipToTheInsured,
                additionalPhoneNumber: values.additionalPhoneNumber,
                additionalEmailAddress: values.additionalEmailAddress,
                additionalMetaProfile: values.additionalMetaProfile,
                premiumAmount: values.premiumAmount,
                FrequencyOfPremiumPayments: values.FrequencyOfPremiumPayments,
                underwriterName: values.underwriterName,
                underwriterContactName: values.underwriterContactName,
                underwriterContactPhone: values.underwriterContactPhone,
                underwriterContactEmail: values.underwriterContactEmail,
                underwriterContactName2: values.underwriterContactName2,
                underwriterContactPhone2: values.underwriterContactPhone2,
                underwriterContactEmail2: values.underwriterContactEmail2,
                underwriterDecisions: values.underwriterDecisions,
                commission: values.commission,
                modifiedOn: new Date(),
                assigned_agent: values.assigned_agent,
            }
            editPolicy(policyData)
        },
    });
    
    // useEffect(() => {
    //     if (userDetails?.length === 0) {
    //         dispatch(fetchUserData())
    //     }
    // }, [open]);

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h6">Edit</Typography>
                    <Typography>
                        <ClearIcon
                            onClick={handleClose}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <form>
                        <DialogContentText
                            id="scroll-dialog-description"
                            tabIndex={-1}
                        >
                            <Typography style={{ marginBottom: "15px" }} variant="h6">
                                Policy Details
                            </Typography>
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                            >
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormControl fullWidth>
                                        <FormLabel>Policy Type <span style={{ color: "red" }}>*</span></FormLabel>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={policyTypeList}
                                            getOptionLabel={(item) => item?.lable}
                                            value={policyTypeList?.find((item) => item?.value === formik.values.policyType)}
                                            onChange={(event, newValue) => {
                                                formik.setFieldValue("policyType", newValue ? newValue?.value : "");
                                            }}
                                            renderInput={(params) =>
                                                <TextField {...params}
                                                    size="small"
                                                    error={formik.touched.policyType && Boolean(formik.errors.policyType)}
                                                    helperText={formik.touched.policyType && formik.errors.policyType}
                                                    placeholder='Select'
                                                />}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Policy Start Date <span style={{ color: "red" }}>*</span></FormLabel>
                                    <TextField
                                        name='policyStartDate'
                                        type='date'
                                        size='small'
                                        fullWidth
                                        value={dayjs(formik.values.policyStartDate).format('YYYY-MM-DD')}
                                        onChange={formik.handleChange}
                                        error={formik.touched.policyStartDate && Boolean(formik.errors.policyStartDate)}
                                        helperText={formik.touched.policyStartDate && formik.errors.policyStartDate}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Policy End Date <span style={{ color: "red" }}>*</span></FormLabel>
                                    <TextField
                                        name='policyEndDate'
                                        type='date'
                                        size='small'
                                        fullWidth
                                        value={dayjs(formik.values.policyEndDate).format('YYYY-MM-DD')}
                                        onChange={formik.handleChange}
                                        error={formik.touched.policyEndDate && Boolean(formik.errors.policyEndDate)}
                                        helperText={formik.touched.policyEndDate && formik.errors.policyEndDate}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormControl fullWidth>
                                        <FormLabel>Policy Status <span style={{ color: "red" }}>*</span></FormLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="policyStatus"
                                            name="policyStatus"
                                            size='small'
                                            fullWidth
                                            value={formik.values.policyStatus}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.policyStatus &&
                                                Boolean(formik.errors.policyStatus)
                                            }
                                            helperText={
                                                formik.touched.policyStatus && formik.errors.policyStatus
                                            }
                                        >
                                            <MenuItem value="Active">Active</MenuItem>
                                            <MenuItem value="InActive">InActive </MenuItem>
                                            <MenuItem value="Canceled">Canceled </MenuItem>
                                        </Select>
                                        <FormHelperText style={{ color: Palette.error.main }}>{formik.touched.policyStatus && formik.errors.policyStatus}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Typography
                                style={{ marginBottom: "15px", marginTop: "15px" }}
                                variant="h6"
                            >
                                Policy Coverage Details
                            </Typography>
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                            >
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Coverage Amounts <span style={{ color: "red" }}>*</span></FormLabel>
                                    <TextField
                                        id="coverageAmounts"
                                        name="coverageAmounts"
                                        size='small'
                                        fullWidth
                                        value={formik.values.coverageAmounts}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.coverageAmounts &&
                                            Boolean(formik.errors.coverageAmounts)
                                        }
                                        helperText={
                                            formik.touched.coverageAmounts && formik.errors.coverageAmounts
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Deductibles <span style={{ color: "red" }}>*</span></FormLabel>
                                    <TextField
                                        id="deductibles"
                                        name="deductibles"
                                        size='small'
                                        fullWidth
                                        value={formik.values.deductibles}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.deductibles &&
                                            Boolean(formik.errors.deductibles)
                                        }
                                        helperText={
                                            formik.touched.deductibles && formik.errors.deductibles
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <FormLabel>Limits <span style={{ color: "red" }}>*</span></FormLabel>
                                    <TextField
                                        id="limits"
                                        name="limits"
                                        size='small'
                                        fullWidth
                                        value={formik.values.limits}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.limits &&
                                            Boolean(formik.errors.limits)
                                        }
                                        helperText={
                                            formik.touched.limits && formik.errors.limits
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Typography
                                style={{ marginBottom: "15px", marginTop: "15px" }}
                                variant="h6"
                            >
                                Insured Details
                            </Typography>
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                            >
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Insured Person Name <span style={{ color: "red" }}>*</span></FormLabel>
                                    <TextField
                                        id="insuredPersonName"
                                        name="insuredPersonName"
                                        size='small'
                                        fullWidth
                                        value={formik.values.insuredPersonName}
                                        onChange={formik.handleChange}
                                        error={formik.touched.insuredPersonName && Boolean(formik.errors.insuredPersonName)}
                                        helperText={formik.touched.insuredPersonName && formik.errors.insuredPersonName}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Date Of Birth <span style={{ color: "red" }}>*</span></FormLabel>
                                    <TextField
                                        name='insuredPersonDateOfBirth'
                                        type='date'
                                        size='small'
                                        fullWidth
                                        value={dayjs(formik.values.insuredPersonDateOfBirth).format('YYYY-MM-DD')}
                                        onChange={formik.handleChange}
                                        error={formik.touched.insuredPersonDateOfBirth && Boolean(formik.errors.insuredPersonDateOfBirth)}
                                        helperText={formik.touched.insuredPersonDateOfBirth && formik.errors.insuredPersonDateOfBirth}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <FormLabel>Relationship to the insured <span style={{ color: "red" }}>*</span></FormLabel>
                                    <TextField
                                        id="relationshipToTheInsured"
                                        name="relationshipToTheInsured"
                                        size='small'
                                        fullWidth
                                        value={formik.values.relationshipToTheInsured}
                                        onChange={formik.handleChange}
                                        error={formik.touched.relationshipToTheInsured && Boolean(formik.errors.relationshipToTheInsured)}
                                        helperText={formik.touched.relationshipToTheInsured && formik.errors.relationshipToTheInsured}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                <FormControl fullWidth>
                                    <FormLabel>Assigned Agent</FormLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="assigned_agent"
                                        name="assigned_agent"
                                        size="small"
                                        multiple
                                        value={formik.values.assigned_agent}
                                        onChange={formik.handleChange}
                                        error={formik.touched.assigned_agent && Boolean(formik.errors.assigned_agent)}
                                        renderValue={(selected) => (
                                            <div>
                                                {
                                                    selected.map((value) => {
                                                        const userData = userDetails?.find((user) => user._id === value)
                                                        return (
                                                            <div key={value}>
                                                                {`${userData?.firstName} ${userData?.lastName}`}
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                        }
                                    >
                                        {
                                            userDetails?.map((user) => (
                                                <MenuItem value={user?._id}>
                                                    <Checkbox checked={(policyData?.assigned_agent || []).findIndex((element) => element._id === user?._id) > -1} onChange={formik.handleChange}/>
                                                    <ListItemText primary={`${user?.firstName} ${user?.lastName}`} />
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                        <FormHelperText
                                            error={
                                                formik.touched.assigned_agent && Boolean(formik.errors.assigned_agent)
                                            }
                                        >
                                        {formik.touched.assigned_agent && formik.errors.assigned_agent}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Typography
                                style={{ marginBottom: "15px", marginTop: "15px" }}
                                variant="h6"
                            >
                                Insured person's contact information
                            </Typography>
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                            >
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Phone Number <span style={{ color: "red" }}>*</span></FormLabel>
                                    <TextField
                                        id=""
                                        name="phoneNumber"
                                        type="number"
                                        size='small'
                                        fullWidth
                                        value={formik.values.phoneNumber}
                                        onChange={formik.handleChange}
                                        error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                        helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Email Address <span style={{ color: "red" }}>*</span></FormLabel>
                                    <TextField
                                        id="emailAddress"
                                        name="emailAddress"
                                        type="email"
                                        size='small'
                                        fullWidth
                                        value={formik.values.emailAddress}
                                        onChange={formik.handleChange}
                                        error={formik.touched.emailAddress && Boolean(formik.errors.emailAddress)}
                                        helperText={formik.touched.emailAddress && formik.errors.emailAddress}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Meta Profile</FormLabel>
                                    <TextField
                                        id="metaProfile"
                                        name="metaProfile"
                                        type=""
                                        size='small'
                                        fullWidth
                                        value={formik.values.metaProfile}
                                        onChange={(e) => formik.setFieldValue('metaProfile', `${e.target.value}`)}
                                    />
                                    {formik.values.metaProfile && <a href={`https://www.meta.com/${formik.values.metaProfile}`} target="_blank" rel="noreferrer">Link</a>}
                                </Grid>
                            </Grid>
                            <Typography
                                style={{ marginBottom: "15px", marginTop: "15px" }}
                                variant="h6"
                            >
                                Additional Insured
                            </Typography>
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                            >
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Person Name</FormLabel>
                                    <TextField
                                        id="additionalInsuredPersonName"
                                        name="additionalInsuredPersonName"
                                        size='small'
                                        fullWidth
                                        value={formik.values.additionalInsuredPersonName}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Date Of Birth</FormLabel>
                                    <TextField
                                        name='additionalInsuredDateOfBirth'
                                        type='date'
                                        size='small'
                                        fullWidth
                                        value={formik.values.additionalInsuredDateOfBirth}
                                        onChange={formik.handleChange}
                                        error={formik.touched.additionalInsuredDateOfBirth && Boolean(formik.errors.additionalInsuredDateOfBirth)}
                                        helperText={formik.touched.additionalInsuredDateOfBirth && formik.errors.additionalInsuredDateOfBirth}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <FormLabel>Relationship to the insured</FormLabel>
                                    <TextField
                                        id="additionalRelationshipToTheInsured"
                                        name="additionalRelationshipToTheInsured"
                                        size='small'
                                        fullWidth
                                        value={formik.values.additionalRelationshipToTheInsured}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>
                            </Grid>
                            <Typography
                                style={{ marginBottom: "15px", marginTop: "15px" }}
                                variant="h6"
                            >
                                Additional insured person's contact information
                            </Typography>
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                            >
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Phone Number</FormLabel>
                                    <TextField
                                        id="additionalPhoneNumber"
                                        name="additionalPhoneNumber"
                                        type="number"
                                        size='small'
                                        fullWidth
                                        value={formik.values.additionalPhoneNumber}
                                        onChange={formik.handleChange}
                                        error={formik.touched.additionalPhoneNumber && Boolean(formik.errors.additionalPhoneNumber)}
                                        helperText={formik.touched.additionalPhoneNumber && formik.errors.additionalPhoneNumber}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Email</FormLabel>
                                    <TextField
                                        id="additionalEmailAddress"
                                        name="additionalEmailAddress"
                                        type="email"
                                        size='small'
                                        fullWidth
                                        value={formik.values.additionalEmailAddress}
                                        onChange={formik.handleChange}
                                        error={formik.touched.additionalEmailAddress && Boolean(formik.errors.additionalEmailAddress)}
                                        helperText={formik.touched.additionalEmailAddress && formik.errors.additionalEmailAddress}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Meta profile</FormLabel>
                                    <TextField
                                        id="additionalMetaProfile"
                                        name="additionalMetaProfile"
                                        type=""
                                        size='small'
                                        fullWidth
                                        value={formik.values.additionalMetaProfile}
                                        onChange={(e) => formik.setFieldValue('additionalMetaProfile', `${e.target.value}`)}
                                    />
                                    {formik.values.additionalMetaProfile && <a href={`https://www.meta.com/${formik.values.additionalMetaProfile}`} target="_blank" rel="noreferrer">Link</a>}
                                </Grid>
                            </Grid>
                            <Typography
                                style={{ marginBottom: "15px", marginTop: "15px" }}
                                variant="h6"
                            >
                                Policy Premiums and Payments
                            </Typography>
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                            >
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Premium Amount</FormLabel>
                                    <TextField
                                        id="premiumAmount"
                                        name="premiumAmount"
                                        type="number"
                                        size='small'
                                        fullWidth
                                        value={formik.values.premiumAmount}
                                        onChange={formik.handleChange}

                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormControl fullWidth>
                                        <FormLabel>Premium Payments</FormLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="FrequencyOfPremiumPayments"
                                            name="FrequencyOfPremiumPayments"
                                            label=""
                                            size='small'
                                            value={formik.values.FrequencyOfPremiumPayments}
                                            onChange={formik.handleChange}
                                        >
                                            <MenuItem value="Monthly">Monthly</MenuItem>
                                            <MenuItem value="Annually">Annually </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormControl fullWidth>
                                        <FormLabel>Commission Earned</FormLabel>
                                        <TextField
                                        id="commission"
                                        name="commission"
                                        type="number"
                                        size='small'
                                        fullWidth
                                        value={formik.values.commission}
                                        onChange={formik.handleChange}/>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Typography
                                style={{ marginBottom: "15px", marginTop: "15px" }}
                                variant="h6"
                            >
                                Underwriting Information
                            </Typography>
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                            >
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Name</FormLabel>
                                    <TextField
                                        id=""
                                        name="underwriterName"
                                        type=""
                                        fullWidth
                                        size='small'
                                        value={formik.values.underwriterName}
                                        onChange={formik.handleChange}

                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Contact Person Name</FormLabel>
                                    <TextField
                                        id=""
                                        name="underwriterContactName"
                                        type=""
                                        fullWidth
                                        size='small'
                                        value={formik.values.underwriterContactName}
                                        onChange={formik.handleChange}

                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Contact Person Phone</FormLabel>
                                    <TextField
                                        id=""
                                        name="underwriterContactPhone"
                                        type=""
                                        fullWidth
                                        size='small'
                                        value={formik.values.underwriterContactPhone}
                                        onChange={formik.handleChange}
                                        error={formik.touched.underwriterContactPhone && Boolean(formik.errors.underwriterContactPhone)}
                                        helperText={formik.touched.underwriterContactPhone && formik.errors.underwriterContactPhone}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Contact Person Email</FormLabel>
                                    <TextField
                                        id=""
                                        name="underwriterContactEmail"
                                        type=""
                                        fullWidth
                                        size='small'
                                        value={formik.values.underwriterContactEmail}
                                        onChange={formik.handleChange}
                                        error={formik.touched.underwriterContactEmail && Boolean(formik.errors.underwriterContactEmail)}
                                        helperText={formik.touched.underwriterContactEmail && formik.errors.underwriterContactEmail}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Contact Person Name</FormLabel>
                                    <TextField
                                        id=""
                                        name="underwriterContactName2"
                                        type=""
                                        fullWidth
                                        size='small'
                                        value={formik.values.underwriterContactName2}
                                        onChange={formik.handleChange}

                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Contact Person Phone</FormLabel>
                                    <TextField
                                        id=""
                                        name="underwriterContactPhone2"
                                        type=""
                                        fullWidth
                                        size='small'
                                        value={formik.values.underwriterContactPhone2}
                                        onChange={formik.handleChange}
                                        error={formik.touched.underwriterContactPhone2 && Boolean(formik.errors.underwriterContactPhone2)}
                                        helperText={formik.touched.underwriterContactPhone2 && formik.errors.underwriterContactPhone2}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Contact Person Email</FormLabel>
                                    <TextField
                                        id=""
                                        name="underwriterContactEmail2"
                                        type=""
                                        fullWidth
                                        size='small'
                                        value={formik.values.underwriterContactEmail2}
                                        onChange={formik.handleChange}
                                        error={formik.touched.underwriterContactEmail2 && Boolean(formik.errors.underwriterContactEmail2)}
                                        helperText={formik.touched.underwriterContactEmail2 && formik.errors.underwriterContactEmail2}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormControl fullWidth>
                                        <FormLabel>Underwriter Remarks</FormLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="underwriterDecisions"
                                            name="underwriterDecisions"
                                            label=""
                                            size='small'
                                            value={formik.values.underwriterDecisions}
                                            onChange={formik.handleChange}
                                        >
                                            <MenuItem value="Policyholder has a clean driving record">Policyholder has a clean driving record</MenuItem>
                                            <MenuItem value="Policyholder's property located in a low-risk area">Policyholder's property located in a low-risk area </MenuItem>
                                            <MenuItem value="Underwriter consulted with the claims department to assess potential risks.">Underwriter consulted with the claims department to assess potential risks. </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                        </DialogContentText>
                    </form>
                </DialogContent>
                <DialogActions>
                    <LoadingButton onClick={formik.handleSubmit} variant='contained' color='primary' disabled={!!isLoading}>
                        {isLoading ? <CircularProgress size={27} /> : 'Save'}
                    </LoadingButton>                    <Button onClick={() => {
                        formik.resetForm()
                        handleClose()
                    }} variant='outlined' color='error'>Cancle</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Edit