import { LoadingButton } from "@mui/lab";
import { Container, CircularProgress, InputAdornment } from "@mui/material";
import Button from "@mui/material/Button";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Iconify from '../../components/iconify';
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import { useState } from "react";
import * as yup from "yup";
import { apiput } from "../../service/api";
import { useNavigate } from 'react-router-dom';

const ChangePassword = () => {

    const userid = sessionStorage.getItem('user_id');
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();


    // -----------  validationSchema
    const validationSchema = yup.object({
        password: yup.string().required("Password is required"),
        newPassword: yup.string().required("Password is required"),
        confirmPassword: yup.string().required("Password is required").oneOf([yup.ref("newPassword"), null], "Password fields must be equal")
    });

    // -----------   initialValues
    const initialValues = {
        password: "",
        newPassword: "",
        confirmPassword: "",
        modifiedOn: ""
    };

    // edit api
    const EditUserPassword = async (values) => {
        setIsLoading(true)

        try {
            const result = await apiput(`api/user/changepassword/${userid}`, values)
            navigate('dashboard/user/changepassword')
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false)
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            const userData = {
                password: values.password,
                newPassword: values.newPassword,
            }
            EditUserPassword(userData)
        },
    });

    return (
        <>
        <Container maxWidth="xl">
                    <Typography variant="h6">Edit Password </Typography>
                    <form>
                        <Grid
                            container
                            rowSpacing={3}
                            columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                        >
                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Current Password <span style={{ color: "red" }}>*</span></FormLabel>
                                <TextField
                                id="password"
                                name="password"
                                label=""
                                size="small"
                                type={showPassword ? 'text' : 'password'}
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                InputProps={{
                                    endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                        </IconButton>
                                    </InputAdornment>
                                    ),
                                }}
                                fullWidth
                                error={
                                    formik.touched.password &&
                                    Boolean(formik.errors.password)
                                }
                                helperText={
                                    formik.touched.password && formik.errors.password
                                }
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>New Password <span style={{ color: "red" }}>*</span></FormLabel>
                                <TextField
                                id="newPassword"
                                name="newPassword"
                                label=""
                                size="small"
                                type={showPassword ? 'text' : 'password'}
                                value={formik.values.newPassword}
                                onChange={formik.handleChange}
                                InputProps={{
                                    endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                        </IconButton>
                                    </InputAdornment>
                                    ),
                                }}
                                fullWidth
                                error={
                                    formik.touched.newPassword &&
                                    Boolean(formik.errors.newPassword)
                                }
                                helperText={
                                    formik.touched.newPassword && formik.errors.newPassword
                                }
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Confirm Password <span style={{ color: "red" }}>*</span></FormLabel>
                                <TextField
                                id="confirmPassword"
                                name="confirmPassword"
                                label=""
                                size="small"
                                type={showPassword ? 'text' : 'password'}
                                value={formik.values.confirmPassword}
                                onChange={formik.handleChange}
                                InputProps={{
                                    endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                        </IconButton>
                                    </InputAdornment>
                                    ),
                                }}
                                fullWidth
                                error={
                                    formik.touched.confirmPassword &&
                                    Boolean(formik.errors.confirmPassword)
                                }
                                helperText={
                                    formik.touched.confirmPassword && formik.errors.confirmPassword
                                }
                                />
                            </Grid>
                        </Grid>
                        <LoadingButton onClick={formik.handleSubmit} variant='contained' color='primary' disabled={!!isLoading}>
                        {isLoading ? <CircularProgress size={27} /> : 'Save'}
                        </LoadingButton>
                    </form>
            </Container>      
        </>
    );
}

export default ChangePassword