/* eslint-disable react/prop-types */
import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useState } from 'react'
import EmailIcon from "@mui/icons-material/Email";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from 'react-router-dom';
import AddSMS from './AddSms';
import TableStyleTwo from '../TableStyleTwo';

const Sms = ({ rows, toggleVisibilitySMS, isVisibleSMS, _id, setUserAction, data, module }) => {

  const [openSMS, setOpenNote] = useState(false);
  const navigate = useNavigate();

  // open note model
  const handleOpenSMS = () => setOpenNote(true);
  const handleCloseSMS = () => setOpenNote(false);
  const statusColor = (status) => {
    switch (status) {
      case 'pending':
        return 'pending'
      case 'deliverd':
        return 'deliverd'
      case 'failed':
        return 'failed'
      default:
        return 'deliverd'

    }
  }

  const columns = [
    {
      field: "senderName",
      headerName: "Sender Name",
      cellClassName: "name-column--cell name-column--cell--capitalize",
      width: 250,
      renderCell: (params) => {
        const handleFirstNameClick = () => {
          navigate(`/dashboard/sms/view/${params.row._id}`)
        };
        return (
          <Box onClick={handleFirstNameClick}>
            {params.value ? params.value : '-'}
          </Box>
        );
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 250,
      headerClassName: 'status-column--cell',

      renderCell: (params) => {
        return (
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Button
              variant="outlined"
              className={statusColor(params?.value?.toLowerCase())}
            >
              {params?.value ? params?.value : '--'}
            </Button>
          </Box>
        );
      },
    },

  ];

  return (
    <div>
      {/* Add email */}
      <AddSMS open={openSMS} handleClose={handleCloseSMS} _id={_id} setUserAction={setUserAction} receiver={data} module={module} />

      <Box
        style={{ cursor: "pointer" }}
        p={2}
      >
        <Grid container display="flex" alignItems="center">
          <Stack direction="row" alignItems="center" justifyContent={"space-between"} width={"100%"}>
            <Stack direction="row" spacing={1} alignItems={"center"}>
              <Button
                onClick={toggleVisibilitySMS}
                color="secondary"
                variant="contained"
                sx={{ width: "28px", minWidth: "0px", padding: "0px", height: "25px" }}
              >
                {isVisibleSMS ? <RemoveIcon /> : <AddIcon />}
              </Button>
              <EmailIcon />
              <Typography variant="h5">SMS</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent={"flex-end"} spacing={2}>
              {isVisibleSMS && (
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={handleOpenSMS}
                >
                  Create SMS
                </Button>
              )}
            </Stack>
          </Stack>
        </Grid>
      </Box>
      {isVisibleSMS &&
        <TableStyleTwo>
          <Box width="100%" height="30vh">
            <DataGrid
              rows={rows}
              columns={columns}
              columnHeaderHeight={40}
              getRowId={row => row._id}

            />
          </Box>
        </TableStyleTwo>
      }

    </div>
  )
}

export default Sms
