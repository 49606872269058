import { LoadingButton } from "@mui/lab";
import { Container, CircularProgress, InputAdornment, Stack } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Button from "@mui/material/Button";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Iconify from "src/components/iconify";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import { useState } from "react";
import * as yup from "yup";
import { apiput } from "../../../service/api";
import { useNavigate } from 'react-router-dom';
import { StyledRoot, StyledSection, StyledContent } from "./styleConf";
import useResponsive from '../../../hooks/useResponsive';

export default function ResetPassword() {
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const mdUp = useResponsive('up', 'md');

    // -----------  validationSchema
    const validationSchema = yup.object({
        newPassword: yup.string().required("Password is required"),
        confirmPassword: yup.string().required("Password is required").oneOf([yup.ref("newPassword"), null], "Password fields must be equal")
    });

    // -----------   initialValues
    const initialValues = {
        newPassword: "",
        confirmPassword: "",
    };

    // edit api
    const resetPassword = async (values) => {
        setIsLoading(true)

        try {
            const otp_id = sessionStorage.getItem('otp_id');
            const result = await apiput(`api/user/resetpassword/${otp_id}`, values)
            if (result && result.status === 200) {
                sessionStorage.clear();
                localStorage.clear();
                navigate('/login')
            } else {
                navigate(`/resetpassword/${otp_id}`)
            }
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false)
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            const userData = {
                newPassword: values.newPassword,
            }
            resetPassword(userData)
        },
    });
    const back = () => {
        navigate('/sendotp');
    }
    return (
        <>
        <StyledRoot>
        {mdUp && (
            <StyledSection>
                <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>Password Reset</Typography>
                <img src="/assets/illustrations/illustration_login.png" alt="Forgot Password" />
            </StyledSection>
        )}
        <Container maxWidth="sm">
            <StyledContent>
            <img src="/assets/AIA.png" width={200} style={{ marginLeft: "-7px" }} />
            <Typography variant="h4" gutterBottom mb={4}>Rest Your Password</Typography>
            <form>
                <Stack spacing={3} mb={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <FormLabel>New Password <span style={{ color: "red" }}>*</span></FormLabel>
                        <TextField
                        id="newPassword"
                        name="newPassword"
                        label=""
                        size="small"
                        type={showPassword ? 'text' : 'password'}
                        value={formik.values.newPassword}
                        onChange={formik.handleChange}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            </InputAdornment>
                            ),
                        }}
                        fullWidth
                        error={
                            formik.touched.newPassword &&
                            Boolean(formik.errors.newPassword)
                        }
                        helperText={
                            formik.touched.newPassword && formik.errors.newPassword
                        }
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <FormLabel>Confirm Password <span style={{ color: "red" }}>*</span></FormLabel>
                        <TextField
                        id="confirmPassword"
                        name="confirmPassword"
                        label=""
                        size="small"
                        type={showPassword ? 'text' : 'password'}
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            </InputAdornment>
                            ),
                        }}
                        fullWidth
                        error={
                            formik.touched.confirmPassword &&
                            Boolean(formik.errors.confirmPassword)
                        }
                        helperText={
                            formik.touched.confirmPassword && formik.errors.confirmPassword
                        }
                        />
                    </Grid>
                </Stack>
                <Grid container spacing={3}>
                    <Grid item xs={6} sm={6} md={6}>
                        <LoadingButton onClick={formik.handleSubmit} variant='contained' color='primary' disabled={!!isLoading}>
                        {isLoading ? <CircularProgress size={27} /> : 'Reset Password'}
                        </LoadingButton>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <Button variant="contained" color="secondary" onClick={back} startIcon={<ArrowBackIosIcon />}>Resend OTP</Button>
                    </Grid>
                </Grid>
            </form>
            </StyledContent>
            </Container>
            </StyledRoot>
        </>
    )
}