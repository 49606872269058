import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, IconButton, InputAdornment, TextField, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { useFormik } from 'formik';
import * as yup from "yup";
import ReCAPTCHA from 'react-google-recaptcha';
import CircularProgress from '@mui/material/CircularProgress';
import { apipost } from '../../../service/api';
import Iconify from '../../../components/iconify';
import env from 'react-dotenv';
// ----------------------------------------------------------------------

export default function LoginForm() {
  const [err, setErr] = useState('')
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [reCAPTCHAToken, setreCAPTCHAToken] = useState("");
  const [isLoading, setIsLogin] = useState(false)
  const initialValues = {
    emailAddress: "",
    password: ""
  }
  // -----------  validationSchema
  const validationSchema = yup.object({
    emailAddress: yup.string().email('Invalid email').required("Email is required"),
    password: yup.string().required("Password is required"),

  });

  const Adddata = async (values) => {
    setIsLogin(true)
    if (!reCAPTCHAToken) {
      setErr("Please verify the captcha");
      setIsLogin(false)
      return;
    }
    setErr("");
    const data = values
    const result = await apipost('api/user/login/', data)

    if (result && result.status === 200) {
      setreCAPTCHAToken("");
      sessionStorage.setItem('user', JSON?.stringify(result?.data?.user))
      sessionStorage.setItem('user_id', result?.data?.user?._id)
      sessionStorage.setItem('userRole', result?.data?.user?.role)
      navigate('/dashboard/app')
    } else {
      navigate('/login')
    }

    setIsLogin(false)
  }

  // formik
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      Adddata(values)
    },
  });


  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={3} mb={2}>
          <TextField
            name="emailAddress"
            label="Email"
            value={formik.values.emailAddress}
            onChange={formik.handleChange}
            error={
              formik.touched.emailAddress &&
              Boolean(formik.errors.emailAddress)
            }
            helperText={
              formik.touched.emailAddress && formik.errors.emailAddress
            }
          />

          <TextField
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            value={formik.values.password}
            onChange={formik.handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={
              formik.touched.password &&
              Boolean(formik.errors.password)
            }
            helperText={
              formik.touched.password && formik.errors.password
            }
          />
        </Stack>
        <ReCAPTCHA
        sitekey={env.RECAPTCHA_SITE_KEY || ""}
        onChange={token => setreCAPTCHAToken(token)}
        onExpired={e => setreCAPTCHAToken("")}
        />
        <Grid item xs={12} style={{ color: "red", paddingTop: "4px", fontSize: "13px" }} >
        {err}
        </Grid>
        <LoadingButton fullWidth size="large" type="submit" variant="contained" disabled={!!isLoading}>
          {isLoading ? <CircularProgress /> : 'Login'}
        </LoadingButton>
        If you Forgot Your Password <a href={'https://crm.anzianoinsuranceagency.com/sendotp'}>Click Here</a>
      </form>
    </>
  );
}
